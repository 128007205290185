import { defineStore } from "pinia";
import type { SeiWallet } from "@sei-js/core";
import { getCosmWasmClient } from "@sei-js/core";



interface State {
  network: "mainnet" | "testnet",
  connecting: boolean,
  connectedWallet: SeiWallet | null,
  connectedAddr: string,
  balance: number,
  loadingBalance: boolean,
}

export const useStore = defineStore("store", {

  state: (): State => {
    const { public: { stage } } = useRuntimeConfig();

    return {
      network: (stage.startsWith("local") || stage === "dev") ? "testnet" : "mainnet",
      connecting: false,
      connectedWallet: null,
      connectedAddr: "",
      balance: 0,
      loadingBalance: false,
    };
  },

  getters: {
    chainId(state) { return state.network === "mainnet" ? "pacific-1" : "atlantic-2" },
    rpcUrl(state) { return state.network === "mainnet" ? "https://rpc.sei-apis.com" : "https://rpc-testnet.sei-apis.com"; },
  },

  actions: {

    async getBalance() {
      if (!this.connectedAddr) return;
      const client  = await getCosmWasmClient(this.rpcUrl);
      const balance = await client.getBalance(this.connectedAddr, "usei");
      this.balance  = parseFloat((parseInt(balance.amount) / 1e6).toFixed(2));
    }

  }

});
