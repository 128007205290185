import type { SeiWallet } from "@sei-js/core";
import * as Sentry from "@sentry/vue";


export class Auth {

  /**
   * Login with SEI wallet
   */
  static async login(wallet: SeiWallet) {

    const store  = useStore();

    try {

      store.connecting = true;

      if (!wallet.connect) throw new Error("Wallet not supported");
      await wallet.connect(store.chainId);
      const [account] = await wallet.getAccounts(store.chainId);

      await new Promise((resolve) => setTimeout(resolve, 1000));

      store.connectedWallet = wallet;
      store.connectedAddr   = account.address;


    } catch (error) {
      Sentry.captureException(error);
    } finally {
      store.connecting = false;
    }

  }

  static logout() {
    const store = useStore();
    store.connectedWallet = null;
    store.connectedAddr   = "";
  }

}
